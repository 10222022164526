import React, { useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { procesaPago, procesaOrden } from "../services/authService";
import styles from "../assets/css/checkout.module.css";
import OrderComments from '../components/Comments';
import CustomerInfo from '../components/CustomerInfo';
import OrderSummary from '../components/OrderSummary';
import PaymentInfo from '../components/PaymentInfo';
import CreditCardDetails from '../components/CreditCardDetails';

const CheckoutPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const cartItems = location.state?.cartItems || [];
  
  const [paymentMethod, setPaymentMethod] = useState('');
  const [comments, setComments] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [cardDetails, setCardDetails] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    cardHolderName: '',
  });
  const [errors, setErrors] = useState({});
  const [paymentError, setPaymentError] = useState(''); // Nuevo estado para error de método de pago

  const handlePaymentChange = (e) => {
    setPaymentMethod(e.target.value);
    setPaymentError(''); // Restablecer el mensaje de error al cambiar el método de pago
  };

  const totalPrice = cartItems.reduce(
    (total, item) => total + parseFloat(item.precio) * item.quantity,
    0
  );

  const validateForm = () => {
    const newErrors = {};
    if (!contactName) newErrors.contactName = 'Nombre de contacto es requerido.';
    if (!contactEmail) newErrors.contactEmail = 'Email es requerido.';
    if (!contactPhone) newErrors.contactPhone = 'Teléfono es requerido.';
    
    if (paymentMethod === 'tarjeta') {
      if (!cardDetails.cardNumber) newErrors.cardNumber = 'Número de tarjeta es requerido.';
      if (!cardDetails.expiryDate) newErrors.expiryDate = 'Fecha de vencimiento es requerida.';
      if (!cardDetails.cvv) newErrors.cvv = 'Código CVV es requerido.';
      if (!cardDetails.cardHolderName) newErrors.cardHolderName = 'Nombre del titular es requerido.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    // Validar si el método de pago está seleccionado
    if (!paymentMethod) {
      setPaymentError('Por favor, seleccione un método de pago.');
      return;
    }

    if (!validateForm()) return;
    const itemsToSend = cartItems.map(({ imgPlatillo, ...rest }) => rest); // Excluye 'imgPlatillo'

    const orderData = {
      restaurantId: id,
      items: itemsToSend,
      totalPrice: totalPrice.toFixed(2),
      paymentMethod,
      comments,
      contactName,
      contactEmail,
      contactPhone,
      cardDetails: paymentMethod === 'tarjeta' ? cardDetails : undefined,
    };
    console.log(orderData)
    const paymentData = paymentMethod === 'tarjeta' ? { cardDetails } : null;

    try {
      // 1. Procesar Pago si es tarjeta de crédito
      if (paymentMethod === 'tarjeta') {
        const paymentResponse = await procesaPago(paymentData); // Llamada a API de pago
        console.log(paymentResponse);
        if (!paymentResponse.success) {
          console.error('Error en el pago:', paymentResponse.data.message);
          return; // Detener el flujo si el pago falla
        }
      }

      // 2. Procesar la Orden después de confirmar el pago exitoso o si es otro método de pago
      const orderResponse = await procesaOrden(orderData);
      console.log(orderResponse);
      console.log('Orden procesada:', orderResponse.orderId);

      // Aquí podrías redirigir al usuario a una página de confirmación o mostrar un mensaje

    } catch (error) {
      console.error('Error al procesar el pago:', error);
    }
  };

  return (
    <div className={styles.contenedor}>
      <div className={styles.container}>
        <h1 className={styles.header1}>Checkout - Orden de Comida</h1>

        <div className={styles.grid}>
          <OrderSummary cartItems={cartItems} totalPrice={totalPrice} />
          <OrderComments comments={comments} setComments={setComments} />
          <CustomerInfo 
            contactName={contactName}
            setContactName={setContactName}
            contactEmail={contactEmail}
            setContactEmail={setContactEmail}
            contactPhone={contactPhone}
            setContactPhone={setContactPhone}
            errors={errors}
          />
          <PaymentInfo paymentMethod={paymentMethod} handlePaymentChange={handlePaymentChange} />

          {paymentMethod === 'tarjeta' && (
            <CreditCardDetails 
              cardDetails={cardDetails} 
              setCardDetails={setCardDetails} 
              errors={errors} 
            />
          )}
        </div>

        {/* Mostrar mensaje de error si no se ha seleccionado un método de pago */}
        {paymentError && <p style={{ color: 'red' }}>{paymentError}</p>}

        <button onClick={handleSubmit}>Procesar Pedido</button>
        <Link to={`/${id}`}>Volver a la página de inicio</Link>
      </div>
    </div>
  );
};

export default CheckoutPage;
