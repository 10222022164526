// CategoryTabs.jsx
import React from "react";

const CategoryTabs = ({ menuColor, activeTab, onTabClick }) => {
  const styles = {
    tabs: {
      display: 'flex',
      justifyContent: 'center',
      margin: '20px 0',
    },
    tab: {
      padding: '10px 20px',
      cursor: 'pointer',
      backgroundColor: '#e0e0e0',
      margin: '0 5px',
      borderRadius: '5px',
      transition: 'background-color 0.3s',
    },
    activeTab: {
      backgroundColor: menuColor,
      color: 'white',
    },
  };
  
  return (
    <div style={styles.tabs}>
      <div
        style={{ ...styles.tab, ...(activeTab === 'food' ? styles.activeTab : {}) }}
        onClick={() => onTabClick('food')}
      >
        Comidas
      </div>
      <div
        style={{ ...styles.tab, ...(activeTab === 'drinks' ? styles.activeTab : {}) }}
        onClick={() => onTabClick('drinks')}
      >
        Bebidas
      </div>
    </div>
  );
};



export default CategoryTabs;
