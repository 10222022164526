import React from "react";

const Footer = ({ title, menuColor }) => {
  const footerStyle = {
    backgroundColor: menuColor, /*"#FE61DC"*/
    color: "white",
    textAlign: "center",
    padding: "10px 0",
    position: "fixed",
    bottom: 0,
    width: "100%",
  };
  return (
    <footer style={footerStyle}>
      <p>&copy; {new Date().getFullYear()} {title}. Todos los derechos reservados.</p>
    </footer>
  );
};



export default Footer;
