import React from 'react';
import styles from '../assets/css/confirmation.module.css'; // Asegúrate de que la ruta sea correcta

const OrderPage = () => {
    const orderData = {
        orderId: '#001234',
        orderStatus: 'En preparación',
        customerName: 'Juan Pérez',
        totalPrice: 250,
        items: [
            { name: 'Tacos al Pastor', quantity: 2 },
            { name: 'Refresco', quantity: 1 },
            { name: 'Guacamole', quantity: 1 }
        ],
        estimatedDeliveryTime: '30 minutos'
    };

    return (
        <div className={styles.page}>
        <div className={styles.container}>
            <header className={styles.header}>
                <h1 className={styles.title}>¡Gracias por tu Pedido!</h1>
                <p className={styles.subtitle}>Tu orden ha sido recibida y está siendo procesada.</p>
            </header>

            <div className={styles.orderSummary}>
                <h2 className={styles.summaryTitle}>Resumen de tu Pedido</h2>
                <p><strong>Pedido ID:</strong> {orderData.orderId}</p>
                <p><strong>Estado de la Orden:</strong> <span className={styles.orderStatus}>{orderData.orderStatus}</span></p>
                <p><strong>Nombre del Cliente:</strong> {orderData.customerName}</p>
                <p><strong>Total:</strong> ${orderData.totalPrice} MXN</p>
            </div>

            <div className={styles.orderItems}>
                <h3 className={styles.itemsTitle}>Comanda:</h3>
                <ul>
                    {orderData.items.map((item, index) => (
                        <li key={index}>{item.name} x{item.quantity}</li>
                    ))}
                </ul>
            </div>

            <div className={styles.orderEstimate}>
                <h3 className={styles.estimateTitle}>Tiempo Estimado de Entrega:</h3>
                <p>{orderData.estimatedDeliveryTime}</p>
            </div>

            <div className={styles.footer}>
                <button className={styles.button} onClick={() => window.location.href = '/menu'}>Volver al Menú</button>
                <button className={styles.button} onClick={() => window.location.href = '/orders'}>Ver Mis Órdenes</button>
            </div>
        </div>
        </div>

    );
};

// Exporta el componente
export default OrderPage;
