import React from 'react';
import styles from "../assets/css/checkout.module.css";

const CreditCardDetails = ({ cardDetails, setCardDetails, errors }) => {
  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 16);
    setCardDetails({ ...cardDetails, cardNumber: value });
  };

  const handleExpiryDateChange = (e) => {
    // Permitir solo números y limitar a 4 dígitos
    const value = e.target.value.replace(/\D/g, '').slice(0, 4);
    
    // Formatear como MM/AA
    const formattedValue = value.length > 2 ? `${value.slice(0, 2)}/${value.slice(2)}` : value;
    setCardDetails({ ...cardDetails, expiryDate: formattedValue });
  };

  const handleCvvChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 3);
    setCardDetails({ ...cardDetails, cvv: value });
  };

  const handleCardHolderNameChange = (e) => {
    const value = e.target.value.replace(/[^A-Za-z\s]/g, '');
    setCardDetails({ ...cardDetails, cardHolderName: value });
  };

  return (
    <div className={styles.creditCardInfo}>
      <h2 className={styles.header2}>Detalles de la Tarjeta de Crédito</h2>
      <label className={styles.label} htmlFor="card-number">Número de Tarjeta</label>
      <input
        type="text"
        id="card-number"
        className={errors.cardNumber ? 'error' : ''}
        value={cardDetails.cardNumber}
        placeholder="1234 5678 9012 3456"
        maxLength="19"
        onChange={handleCardNumberChange}
      />
      {errors.cardNumber && <span style={{ color: 'red' }}>{errors.cardNumber}</span>}
      
      <label className={styles.label} htmlFor="expiry-date">Fecha de Vencimiento (MM/AA)</label>
      <input
        type="text"
        id="expiry-date"
        placeholder="MM/AA"
        className={errors.expiryDate ? 'error' : ''}
        value={cardDetails.expiryDate}
        maxLength="5" // Actualizar maxLength para incluir el formato
        onChange={handleExpiryDateChange}
      />
      {errors.expiryDate && <span style={{ color: 'red' }}>{errors.expiryDate}</span>}
      
      <label className={styles.label} htmlFor="cvv">CVV</label>
      <input
        type="password"
        id="cvv"
        placeholder="123"
        maxLength="3"
        className={errors.cvv ? 'error' : ''}
        value={cardDetails.cvv}
        onChange={handleCvvChange}
      />
      {errors.cvv && <span style={{ color: 'red' }}>{errors.cvv}</span>}
      
      <label className={styles.label} htmlFor="card-holder-name">Nombre del Titular</label>
      <input
        type="text"
        id="card-holder-name"
        className={errors.cardHolderName ? 'error' : ''}
        value={cardDetails.cardHolderName}
        placeholder="Nombre del titular"
        onChange={handleCardHolderNameChange}
      />
      {errors.cardHolderName && <span style={{ color: 'red' }}>{errors.cardHolderName}</span>}
    </div>
  );
};

export default CreditCardDetails;
